<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="额度申请编号">
              <el-input v-model="searchForm.quotaCode" placeholder="请输入要搜索的额度申请编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资金方名称">
              <el-input v-model="searchForm.capName" placeholder="请输入要搜索的资金方名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="核心企业名称">
              <el-input v-model="searchForm.coreName" placeholder="请输入要搜索的核心企业名称" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="searchForm.applyStatus" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in _.filter(constants.coreQuotaStatus,{isShow: 1})" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="table-columns-before">
          <el-table-column label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="140">
            <template v-slot="scope">
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'coreQuotaDetail', query: { id: scope.row.id } })">
                详情
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        quotaCode: '',
        capName: '',
        coreName: '',
        applyStatus: '',
        createdBeginAt: '',
        createdEndAt: ''
      },
      columnOption: [
        { label: '额度申请编号', prop: 'quotaCode', formatter: this.utils.isEffective, minWidth: 100 },
        { label: '核心企业名称', prop: 'coreName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '社会统一信用代码', prop: 'coreSocialNo', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '资金方名称', prop: 'capName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '申请额度', prop: 'applyAmount', formatter: this.utils.tableMoneyFormat, minWidth: 140 },
        { label: '批复额度', prop: 'passAmount', formatter: this.utils.tableMoneyFormat, minWidth: 140 },
        { label: '有效期', formatter: (row, col, cell) => this.dateFormat(row, col, cell), minWidth: 180 },
        { label: '状态', prop: 'applyStatus', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'quotaApplyStatus'), minWidth: 100 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('createdBeginAt', 'createdEndAt')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.quota.core.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    dateFormat (row, col, cell) {
      let date = ''
      if (row.createdAt && row.deadline) {
        const createdAt = this.utils.dateFormat(row.createdAt)
        const deadline = this.utils.dateFormat(row.deadline)
        date = `${createdAt} ~ ${deadline}`
      } else {
        date = '-'
      }
      return date
    }
  }
}
</script>

<style>

</style>
